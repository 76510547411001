import { Link } from "gatsby"
import * as React from "react"
import DropdownMenu from "./DropdownMenu"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import THEME from "../../theme"
import { faPhoneSquare, faScrollOld, faUserFriends } from "@fortawesome/pro-solid-svg-icons"


// tslint:disable-next-line:max-func-body-length
export default () => (
  <DropdownMenu title="About us">

    {/*
            'About' flyout menu, show/hide based on flyout menu state.

            Entering: "transition ease-out duration-200"
              From: "opacity-0 translate-y-1"
              To: "opacity-100 translate-y-0"
            Leaving: "transition ease-in duration-150"
              From: "opacity-100 translate-y-0"
              To: "opacity-0 translate-y-1"
          */}
    <div className="absolute left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 z-40">
      <div className="rounded-lg shadow-lg">
        <div className="rounded-lg shadow-xs overflow-hidden">
          <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <Link
              to="/team/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faUserFriends} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  Team
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </Link>
            <Link
              to="/history/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faScrollOld} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  History
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </Link>
            <Link
              to="/contact/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faPhoneSquare} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  Contact
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </DropdownMenu>
)