import DropdownMenu from "./DropdownMenu"
import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInboxIn, faChessQueenAlt, faKeynote, faUsersClass, faBalanceScaleLeft, faAbacus, faPhone} from "@fortawesome/pro-solid-svg-icons"
import THEME from "../../theme"

// tslint:disable-next-line:max-func-body-length
export default () => (
 <DropdownMenu title="What we do">
    {/*
            'What we do' flyout menu, show/hide based on flyout menu state.

            Entering: "transition ease-out duration-200"
              From: "opacity-0 translate-y-1"
              To: "opacity-100 translate-y-0"
            Leaving: "transition ease-in duration-150"
              From: "opacity-100 translate-y-0"
              To: "opacity-0 translate-y-1"
          */}
    <div
      className="absolute -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 z-40"
    >
      <div className="rounded-lg shadow-lg">
        <div className="rounded-lg shadow-xs overflow-hidden">
          <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <Link
              to="/delivery/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faInboxIn} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  Delivery
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </Link>
            <Link
              to="/strategy/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faChessQueenAlt} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  Strategy
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </Link>
            <Link
              to="/speaking/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faKeynote} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  Speaking
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Looking for a keynote speaker or email panelist?
                </p>
              </div>
            </Link>
            <Link
              to="/training/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faUsersClass} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  Training
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Bespoke training for your team.
                </p>
              </div>
            </Link>
            <Link
              to="/expert/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faBalanceScaleLeft} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  Expert Witness
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Witness and consulting experts for email and internet cases
                </p>
              </div>
            </Link>
            <Link
              to="/abacus/"
              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
            >
              <FontAwesomeIcon icon={faAbacus} color={THEME.icon.color} fixedWidth={true} size={THEME.icon.size}/>
              <div className="space-y-1">
                <p className="text-base leading-6 font-medium text-gray-900">
                  Software
                </p>
                <p className="text-sm leading-5 text-gray-500">
                  Abacus is our software solution for incident response ticketing
                </p>
              </div>
            </Link>
          </div>
          <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
            <div className="flow-root">
              <Link
                to="/contact/"
                className="-m-3 p-3 flex items-center space-x-3 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
              >
                <FontAwesomeIcon icon={faPhone} color={THEME.icon.colorSubdued} fixedWidth={true} size={THEME.icon.size}/>
                <span>Contact Sales
                      </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
 </DropdownMenu>
)