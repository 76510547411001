import type { SizeProp } from "@fortawesome/fontawesome-svg-core"

let THEME: {
  icon
    : { color: string; colorSubdued: string, size: SizeProp }
}

export default THEME = {
  icon: {
    color: "blue",
    colorSubdued: "grey",
    size: "lg",
  },
}

